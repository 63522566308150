import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import { SEO } from "../components/SEO/SEO";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

import { PortfolioProvider } from '../context/context';

import { headerData, footerData } from '../mock/data';

function Privacy() {
  const [header, setHeader] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHeader({ ...headerData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ header, footer }}>
      <Layout>
      <SEO title={'Nick Jeske - Privacy | Software Engineer & Blogger'}/>
      <div className="pageText">
      <h1>Privacy</h1>
        <p>This privacy policy describes how nickjeske.com uses and protects any information that you give
        nickjeske.com when you use this website
        </p>
        <p>nickjeske.com is committed to ensuring that your privacy is protected. Should we ask you to
        provide certain information by which you can be identified when using this website, then you can be
        assured that it will only be used in accordance with this privacy statement. nickjeske.com may
        change this policy from time to time by updating this page. You should check this page from time to
        time to ensure that you are happy with any changes.
        </p>
        <h4>This policy is effective from 01/01/2021</h4>
        <p>What we collect: We may collect the following information: name and job title contact information,
        including email address demographic information, such as postcode, preferences and interests,
        other information relevant to customer surveys and/or offers
        </p>
        <h4>What we do with the information we gather</h4>
        <p>We require this information to understand your needs and provide you with a better service,
        particularly for the following reasons: Internal record keeping We may use the information to
        improve our products and services. We may periodically send promotional emails about new
        products, special offers or other information which we think you may find interesting, using the
        email address which you have provided
        </p>
        <p>From time to time, we may also use your information to contact you for market research purposes.
        We may contact you by email, phone, fax or mail. We may use the information to customise the
        website according to your interests
        </p>
        <h4>Security</h4>
        <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised
        access or disclosure, we have put in place suitable physical, electronic and managerial procedures to
        safeguard and secure the information we collect online.
        </p>
        <h4>Cookies</h4>
        <p>We use technologies, such as cookies (https://www.aboutcookies.org), to customise content and 
        advertising, to provide social media features and to analyse traffic to the site. We may also share 
        information about your use of our site with our trusted social media, advertising and analytics partners.
        </p>
        <h4>Links to other websites</h4>
        <p>Our website may contain links to other websites of interest. However, once you have used these
        links to leave our site, you should note that we do not have any control over that other website.
        Therefore, we cannot be responsible for the protection and privacy of any information which you
        provide whilst visiting such sites and such sites are not governed by this privacy statement. You
        should exercise caution and look at the privacy statement applicable to the website in question.
        </p>
        <h4>Controlling your personal information</h4>
        <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
        <p>Whenever you are asked to fill in a form on the website, you will always be given the opportunity to 
        unsubscribe or be removed from any mailings or marketing. If you have previously agreed to us using your 
        personal information for direct marketing purposes, you may change your mind at any time by writing to or 
        emailing us at nickjeskedeveloper@gmail.com.
        </p>
        <p>We will not sell, distribute or lease your personal information to third parties unless we have your 
        permission or are required by law to do so. We may use your personal information to send you promotional 
        information about third parties which we think you may find interesting if you tell us that you wish this to happen.
        </p>
        </div>
      </Layout>
    </PortfolioProvider>
  );
}
export default Privacy;

// export const Head = () => (
//   <SEO title={'Nick Jeske - Privacy | Software Engineer & Blogger'}/>
// );